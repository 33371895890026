import React, { useState } from 'react'

import './search-input-filter.scss'
import Downshift from 'downshift'

const SearchInputFilter = props => {
  const [history, setHistory] = useState(false);
  const [listHistory, setListHistory] = useState(false);
  return (
    <Downshift
      itemToString={item => item ? item.metaTitulo : props.inputValue}
    >
      {({
        getInputProps,
        getItemProps,
        getMenuProps,
        isOpen,
        inputValue,
        highlightedIndex,
        selectedItem,
        clearSelection,
      }) => (
          <div className="f-input-search-container">
            <input  {...getInputProps({
              type: "search",
              name: "q",
              placeholder: 'Escribe aquí',
              onChange: e => {
                if (e.target.value === '') {
                  props.search('')
                  props.setValue('')
                }

                clearSelection()
                props.setValue(e.target.value)
              },
            })}
              className={"f-input-search"}
              onKeyPress={event => {
                if (event.key === 'Enter') {
                  event.target.blur();
                  props.search(inputValue)
                  if (event.target.value === '') {
                    clearSelection()
                  }
                }
              }}
              onFocus={() => {
                if (localStorage.getItem('HISTORY') !== null) {
                  var listHistory = JSON.parse(localStorage.getItem('HISTORY'));
                  listHistory.reverse()
                  setListHistory(listHistory)
                  setHistory(true)
                }
              }}
              onBlur={() => setHistory(false)}

            />
            <ul {...getMenuProps()}>
              {isOpen
                ? props.dataSearch
                  .filter(item => (!inputValue && inputValue !== '') || (item.metaTitulo.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().trim().includes(inputValue.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().trim()) && inputValue !== ''))
                  .map((item, index) => (
                    <li
                      {...getItemProps({
                        key: index,
                        index,
                        item,
                        style: {
                          backgroundColor:
                            highlightedIndex === index ? '#5e4778' : '#ececec',
                          fontWeight: selectedItem === item ? 'bold' : 'normal'
                        },
                      })}
                    >
                      {item.metaTitulo}
                    </li>
                  ))
                : null}
            </ul>
            {!inputValue && history && <ul>
              {listHistory.map((item, index) => (
                <li  {...getItemProps({
                  key: index,
                  index,
                  item,
                  style: {
                    backgroundColor:
                      highlightedIndex === index ? '#5e4778' : '#ececec',
                    fontWeight: selectedItem === item ? 'bold' : 'normal'
                  },
                })}   >
                  {item.metaTitulo}
                </li>
              ))
              }

            </ul>}
            {
              props.inputValue !== '' ?
                <>
                  <span onClick={() => inputValue !== '' && props.search(inputValue)} className="icon-BUSCADOR f-icon-search-input" />
                  <span
                    className="fal fa-times f-icon-clean-input"
                    onClick={() => { props.setValue(""); props.search(''); clearSelection() }}
                  ></span>

                  {/* <span
                    className="f-icon icon2-close f-icon-clean-input"
                    onClick={() => { props.setValue(""); clearSelection() }}
                  /> */}
                </>
                :
                <span onClick={() => inputValue !== '' && props.search(inputValue)} className="icon-BUSCADOR f-icon-search-input" />
            }
          </div>

        )}
    </Downshift>
  )
}

export default SearchInputFilter
