import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import Layout from "../shared/components/organisms/layout/layout"
import useMobie from "../shared/hooks/useMobile"
import SearchContent from "../components/organisms/search-content/search-content"
import SEO from "../shared/components/atoms/seo/seo"
import "../shared/styles/index.scss"

// import SEO from "../shared/components/atoms/seo/seo"

const ContentPage = props => {
  // send active modal to layout
  const [modalActive, setModalActive] = useState(null)
  const [dataSearch, setDataSearch] = useState(null)
  const isMobile = useMobie()

  // const searchData = props.data.allContentfulPetysArticulos.nodes[0];

  useEffect(() => {
    const articleAux = props.data.allContentfulPetysArticulos.nodes
    var articles = articleAux.map(function (el) {
      var o = Object.assign({}, el)
      o.contentType = "article"
      return o
    })

    const productsAux =
      props.data.allContentfulPetysSeccionProductos.nodes[0].productos
    var products = productsAux.map(function (el) {
      var o = Object.assign({}, el)
      o.contentType = "product"
      return o
    })

    // console.log("ContentPage -> articles", articles)

    setDataSearch(articles.concat(products))
  }, [])

  return (
    <Layout modalActive={modalActive}>
      <SEO
        title={"Buscador"}
        description={"Buscador petys"}
        slug={"buscador/"}
        lang="ES"
        meta={[
          { name: "no-follow", content: "true" },
          { name: "no-index", content: "true" },
        ]}
      />
      <SearchContent
        modalActive={modalActive}
        isMobile={isMobile}
        dataSearch={dataSearch}
      />
    </Layout>
  )
}

export const query = graphql`
  query SearchQuey {
    allContentfulPetysArticulos(
      sort: { fields: fechaDePublicacionDelArticulo, order: DESC }
    ) {
      nodes {
        slugDelArticulo
        nombreDelArticulo
        autorDelArticulo
        metaTitulo
        metaDescripcion {
          metaDescripcion
        }
        categoriasDelArticulo {
          nombreCategoria
          colorCategoria
        }
        fechaDePublicacionDelArticulo(formatString: "DD/MM/YYYY")
        imagenDePrevisualizacionDelArticulo {
          description
          title
          file {
            url
          }
        }
        imagenDePrevisualizacionMiniDelArticulo {
          title
          file {
            url
          }
        }
        contentful_id
      }
    }
    allContentfulPetysSeccionProductos {
      nodes {
        productos {
          reviewCount
          ratingValue
          nombreDelProducto
          imagenDelProducto {
            title
            description
            file {
              url
            }
          }
          metaTitulo
          metaDescripcion {
            metaDescripcion
          }
          queTipoDeProductoEs
          slug
        }
      }
    }
  }
`

export default ContentPage
