import React, { useState } from 'react'
import BreadCrumb from '../../../shared/components/atoms/breadcrumb/breadcrumb'
import SearchInputFilter from '../../atoms/search-input-filter/search-input-filter';
import CardArticle from '../../../shared/components/atoms/card-article/card-article';
import CardProduct from '../../../shared/components/atoms/card-product/card-product';
import BlogSearchResult from '../../molecules/blog-search-result/blog-search-result'
import Button from '../../../shared/components/atoms/button/button';
import { localStorage } from "../../../shared/utils/storage";
import './search-content.scss'

const WeContent = (props) => {

  const { isMobile, dataSearch, setActiveModal } = props

  const [inputValue, setValue] = useState('');
  const [itemsFilter, setItemsFilter] = useState([])
  const [valueSerched, setSerched] = useState('')
  const [numArticles, setnumArticles] = useState(6);

  /** logic filter searching */
  const search = (value) => {
    if (localStorage.getItem('HISTORY') !== null) {
      var listHistory = localStorage.getItem('HISTORY');
      value !== '' && listHistory.push({ metaTitulo: value })
      localStorage.setItem("HISTORY", listHistory);
    } else {
      value !== '' && localStorage.setItem("HISTORY", [{ metaTitulo: value }]);
    }

    setSerched(value)
    setnumArticles(6)
    if (value !== "" || value !== " ") {
      if (dataSearch) {
        setItemsFilter(dataSearch.filter((info) =>
          info.metaTitulo && info.metaTitulo.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().indexOf(value.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().trim()) > -1 ||
          info.metaDescripcion && info.metaDescripcion.metaDescripcion.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().indexOf(value.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().trim()) > -1
        ))
      }
    } else {
      setItemsFilter([])
    }
  }

  return (
    <section className={"f-search-section " + (valueSerched !== '' ? 'orange' : '')}>

      <div className={"f-breadcrum-position " + (valueSerched === '' ? 'gray' : '')}>
        <BreadCrumb mobile={isMobile} />
      </div>

      {valueSerched === '' && <h1 className="f-title--purple f-title-search">Buscador</h1>}

      <div className="f-search-container">

        <i className={"far fa-arrow-left f-return-icon " + (itemsFilter && itemsFilter.length <= 0 || !valueSerched ? 'purple' : '')} onClick={() => window.history.back()}></i>

        <SearchInputFilter
          setValue={setValue}
          inputValue={inputValue}
          dataSearch={dataSearch}
          search={search}
          mobile={props.mobile}
        />

      </div>

      {valueSerched !== '' && < p className="f-search-result-text">{`${itemsFilter.length} Resultados para “${valueSerched}”`}</p>}

      {valueSerched !== '' &&
        < div className="f-search-result-content ">


          <div className="f-search-resilt-grid">

            {itemsFilter && itemsFilter.length > 0 &&

              itemsFilter.map((element, index) => {
                if (index >= numArticles) {
                  return null
                }

                if (element.contentType === "article") {

                  return <CardArticle
                    key={index}
                    parentSlug="blog-de-expertos/"
                    className="f-card-article-result"
                    article={element}
                    type={2}
                  />

                } else {

                  return <CardProduct
                    className="f-card-product-result"
                    product={element}
                    parentSlug="productos/"
                    key={index}
                  />

                }

              })
            }

          </div>

          {itemsFilter.length <= 0 && valueSerched !== "" &&

            < BlogSearchResult
              searchValue={valueSerched}
              className="f-no-result"
            />

          }

          {itemsFilter && itemsFilter.length > numArticles &&
            <Button
              className="f-primary-purple f-show-more"
              typeButton="button"
              title='Cargar más'
              onClick={() => setnumArticles(numArticles + 6)}
            />
          }

        </div>
      }

    </section >
  )
}

export default WeContent
